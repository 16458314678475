import {useField} from "formik";
import React from "react";
import styled from "@emotion/styled";

// Styled components ....
const StyledSelect = styled.select`
  color: var(--blue-700);
`;



const StyledLabel = styled.label`
  margin-top: 1rem;
`;

const Select = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <>
            <div className="select-wrapper">
            <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
            <StyledSelect {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className={"error"}>{meta.error}</div>
            ) : null}
            </div>
        </>
    );
};

export default Select;