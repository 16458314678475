import '../sass/components/privacy-terms-conditions.scss';

function TermsConditions () {

    return (
        <div className="page terms-conditions-page">
            <h1 className='terms-conditions-page-title'>Terms & Conditions</h1>

                <h2>1. Phasellus commodo</h2>
                <p>Phasellus commodo, nibh nec ultrices pellentesque, nulla est blandit justo, a vehicula diam lorem at velit. Duis nec venenatis urna. Quisque feugiat venenatis arcu vel imperdiet. In imperdiet fringilla neque vel pulvinar. Ut felis velit, accumsan eu est viverra, euismod pretium purus. Praesent tempus mollis risus. Vestibulum fringilla est ac ante suscipit mattis. Quisque id semper sapien. Morbi ac erat sed nulla tristique egestas. Morbi vulputate magna quis lobortis gravida. Donec tristique elit eget nisi maximus sodales. </p>
                <h2>2. Duis vestibulum</h2>
                <p>Duis vestibulum pulvinar sem vitae pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi metus tortor, blandit a tincidunt porttitor, rutrum vitae sem. Aliquam consectetur nisl eros. In hac habitasse platea dictumst. Donec lacinia sapien ex, ornare blandit elit convallis ac. Nunc ante justo, scelerisque id imperdiet eget, tristique vel risus.</p>

        </div>
    )
}
export default TermsConditions