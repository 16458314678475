import userIcon from "../assets/user.svg";
import closeIcon from "../assets/close.svg";
import {Link} from "react-router-dom";
import userIconLink from "../assets/account-icon.svg";
import BasketList from "./BasketList";
import {LogoutButton} from "./LogoutButton";
import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

import BasketButton from "./BasketButton";
import OutsideAlerter from "../hooks/useOutsideClick";
import {useSelector} from "react-redux";
import bottleicon from "../assets/bottle.png";
import caseicon from "../assets/case.png";
import {numberWithCommas} from "../utils/extraTools";

export default function BasketPopup () {
    const [userMenu, setUserMenu] = useState(false)
    const [basketPopup, setBasketPopup] = useState(false)

    const {user} = useAuth0();
    function toggleBasketPopup () {
        setBasketPopup(!basketPopup)
    }
    function togglePopup () {
        setUserMenu(!userMenu)
    }
    const cart = useSelector((state) => state.cart)

    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    let totalItems = 0
    let totalCases = 0
    let totalBottles = 0
    cart.items&&cart.items.forEach(el => {
        totalNett+=el.CasePrice*el.CasesQty+el.BottlesQty*el.BottleNett
        totalVAT+=el.BottleVAT*(el.CasesQty*el.CaseSize+el.BottlesQty)
        grandTotal = totalNett + totalVAT
        totalItems += el.CasesQty+el.BottlesQty
        totalCases += el.CasesQty
        totalBottles += el.BottlesQty
    })
    return (
        <OutsideAlerter setAction={setUserMenu} classNames={'header_menu basket-popup'}>
            <div className='basket-button'>
                <BasketButton toggleBasketPopup={toggleBasketPopup}/>

                <div className={`basket-list-popup ${basketPopup && 'opened'}`}>
                    <BasketList/>
                </div>
            </div>

            <div className={`user-menu ${userMenu && 'opened'}`}>
                <Link to='/basket'>
                    {/* change string to below and remove link above (onclick togglePopup to popup appear)
                    <div className='user-menu-icon 'onClick={togglePopup}>*/}
                    <button className="mobile-basket-btn icon" type="button" >
                        <img src={caseicon} className='mobile-case-icon' alt="mobile-case-icon" />
                        <span className='mobile-case-total-items'>{totalCases}</span>
                        <img src={bottleicon} className='mobile-bottle-icon' alt="mobile-bottle-icon" />
                        <span className='mobile-bottle-total-items'>{totalBottles}</span>
                    </button>
                </Link>
                <ul className='user-menu-dropdown'>
                    <li className='user-menu-close' onClick={togglePopup}><img src={closeIcon} alt="user-menu-close-icon"/></li>
                    <li className='user-menu-dropdown_item'>{user.name}</li>
                    <li className='user-menu-dropdown_item'>
                        <Link to='/account' className="user-menu-account-link">
                            <img src={userIconLink} className='user-menu-account-link-icon' alt="account icon"/>
                            <span>My Account</span>
                        </Link>
                    </li>
                    {/*<li className='user-menu-dropdown_item user-menu-dropdown_item_busket'><BasketList/></li>*/}
                    <li className='user-menu-dropdown_item user-menu-dropdown_item_busket'>{cart.items.length>0&&<BasketList/>}</li>
                    <li className='user-menu-dropdown_item user-menu-logout_item'><LogoutButton/></li>
                </ul>
            </div>
        </OutsideAlerter>
    )
}