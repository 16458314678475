import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import Paper from '@mui/material/Paper';
import {
    SummaryState,
    GroupingState,
    IntegratedGrouping,
    IntegratedSummary,
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableSummaryRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import DateInput from "./DateInput";
import {refactorDateUs, refactorDate} from "../utils/dateRefactor";
import {fetchReserves} from "../apiRequests/api";
import Loading from "./Loading";
import {GridExporter} from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';

const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'StockReport.xlsx');
    });
};

const summaryCalculator = (type, rows, getValue) => {
    if (type === 'median') {
        if (!rows.length) {
            return null;
        }
        const SecondaryDescription = rows[0].SecondaryDescription
        return (SecondaryDescription)
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const GroupCellContent = ({column, row}) => (
    <span>
      {row.value}
    </span>
);


function StockReport() {
    const [fields, setFields] = useState({startDate: '01/01/2024', endDate: refactorDateUs(new Date)})
    const [errors, setErrors] = useState({startDate: false, endDate: false})
    const [orders, setOrders] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 20, 50, 100]);
    const [orderItems, setOrderItems] = useState([]);
    const [orderItem, setOrderItem] = useState([]);
    const [orderItemsTable, setOrderItemsTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()
    let fetchParameters = [];

    async function loadAllReserves() {
        setIsLoading(true)
        const token = await getIdTokenClaims()
        const data = await fetchReserves(fetchParameters, token.__raw)
        if (data.error) {
            // setOrders(data)
        } else {
            setOrders(data.mainResult)
        }

        setIsLoading(false)
    }

    // date validation and loading orders

    function handleChange(field, value) {
        const newFields = {...fields};
        newFields[field] = refactorDateUs(value)
        setFields(newFields);
    }

    useEffect(() => {
        loadAllReserves()
    }, [])

    const [columns] = useState([
        {name: 'Description', title: 'Item'},
        {name: 'RotationCode', title: 'Rotation Code'},
        {name: 'FreeCases', title: 'Case Stock'},
        {name: 'FreeBottles', title: 'Bottle Stock'},
    ]);
    const exporterRef = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    return (
        <div className="page reports-page">

            <Loading isLoading={isLoading}/>
            <h2 className='page-title'>Stock Report</h2>
            <Paper isLoading={isLoading}>
                <Grid
                    rows={orders}
                    columns={columns}
                >
                    <SearchState defaultValue=""/>
                    <Table/>
                    <TableHeaderRow/>
                    <Toolbar/>
                    <SearchPanel/>
                    <ExportPanel startExport={startExport}/>

                </Grid>
                <GridExporter
                    ref={exporterRef}
                    rows={orders}
                    columns={columns}
                    onSave={onSave}
                />
            </Paper>
        </div>
    )
}

export default StockReport