import {Router, Redirect, Route, Switch} from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import 'reset-css';
import './sass/base/base.scss';
import './sass/base/layout.scss';
import './sass/app.scss';
import ProductListPage from "./components/ProductListPage";
import Basket from "./components/Basket";
import Login from "./components/Login";
import ProdItem from "./components/ProdItem";
import ProdDetails from "./components/ProdDetails";
import Reports from "./components/Reports";
import OrdersReport from "./components/OrdersReport";
import OrderItemsReport from "./components/OrderItemsReport";
import ProductStockReport from "./components/ProductStockReport";
import ProductSearch from "./components/ProductSearch";
import StockReport from "./components/StockReport";
import StockList from "./components/StockList";
import StockItem from "./components/StockItem";
import Account from "./components/Account";
import Orders from "./components/Orders";
import Checkout from "./components/Checkout";
import Privacy from "./components/Privacy";
import TermsConditions from "./components/TermsConditions";
import Thankyou from "./components/Thankyou";
import OrderDetails from "./components/OrderDetails";
import TastingNotes from "./components/TastingNotes";
import {useAuth0} from "@auth0/auth0-react";
import history from "./utils/history";
import {ReactSession} from "react-client-session";
import * as Modules from "./components/Modules";
import StorageRequest from "./components/StorageRequest";


function App() {
    const {isLoading, isAuthenticated} = useAuth0();
    // loading status from auth0 api
    if (isLoading) {
        return <Loading/>;
    } else {
        if (isAuthenticated) {
            ReactSession.setStoreType("localStorage");
            return (
                <Router history={history}>
                    <div className="App">
                        <Header/>
                        <div className="content container">
                            <Switch>
                                <Route path="/product-list/:pageParam/:pageSizeParam">
                                    <ProductListPage/>
                                </Route>
                                <Route exact path="/product-search/:pageParam/:pageSizeParam">
                                    <ProductSearch/>
                                </Route>
                                <Route exact path="/stock-report">
                                    <StockReport/>
                                </Route>
                                <Route exact path="/account">
                                    <Account/>
                                </Route>
                                <Route exact path="/orders">
                                    <Orders/>
                                </Route>
                                <Route exact path="/order-details/:orderNo">
                                    <OrderDetails/>
                                </Route>
                                <Route exact path="/basket">
                                    <Basket/>
                                </Route>
                                <Route exact path="/checkout">
                                    <Checkout/>
                                </Route>
                                <Route exact path="/reports">
                                    <Reports/>
                                </Route>
                                <Route exact path="/orders-report">
                                    <OrdersReport/>
                                </Route>
                                <Route exact path="/order-items-report">
                                    <OrderItemsReport/>
                                </Route>
                                <Route exact path="/product-stock-report">
                                    <ProductStockReport/>
                                </Route>
                                <Route exact path="/storage-request">
                                    <StorageRequest/>
                                </Route>
                                <Route exact path="/login">
                                    <Login/>
                                </Route>
                                <Route path="/product-detail/:id/">
                                    <ProdDetails/>
                                </Route>
                                <Route path="/tasting-notes">
                                    <TastingNotes/>
                                </Route>
                                <Route path="/privacy">
                                    <Privacy/>
                                </Route>
                                <Route path="/terms-conditions">
                                    <TermsConditions/>
                                </Route>
                                <Route path="/thank-you/:orderId">
                                    <Thankyou/>
                                </Route>
                                <Route path="/send">
                                </Route>
                                <Redirect from='/product-list' to='/product-list/1/20' exact/>
                                <Redirect from='/product-search' to='/product-search/1/20' exact/>
                                <Redirect to={isAuthenticated ? '/product-search/1/20' : '/login'}/>
                            </Switch>
                        </div>
                        <Footer/>
                    </div>
                </Router>
            );
        } else {
            return (
                <Router history={history}>
                    <div className="App">
                        <Header/>
                        <div className="content container">
                            <Switch>
                                <Route exact path="/trade-account">
                                    {process.env.REACT_APP_PROSPECTS === "1" &&
                                        <Modules.Fallback fallback={<Redirect to='/login'/>}>
                                            <Modules.Prospects/>
                                        </Modules.Fallback>
                                    }
                                </Route>
                                <Route path="/login">
                                    <Login/>
                                </Route>
                                <Redirect to='/login'/>
                            </Switch>
                        </div>
                    </div>
                </Router>
            )
        }
    }
}

export default App;
