import React from 'react';
import {uploadFile} from "../apiRequests/api";







class UploadFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {file: '', msg: ''};
    }

    onFileChange = (event) => {
        this.setState({
            file: event.target.files[0]
        });
    }

    uploadFileData = (event) => {

        event.preventDefault();
        this.setState({msg: ''});

        let data = new FormData();
        data.append('file', this.state.file);

        uploadFile(data);

    }

    render() {
        return (
<div className={"upload"}>
    <p>Upload File</p>
                <p>{this.state.msg}</p>
                <input onChange={this.onFileChange} type="file"></input>
                <button disabled={!this.state.file} onClick={this.uploadFileData}>Upload</button>
</div>
        )
    }

}

export default UploadFile;