import {Link} from "react-router-dom";

import '../sass/header/header.scss';
import '../sass/header/header-logo.scss';
import '../sass/header/user-menu.scss';
import '../sass/header/basket-list-popup.scss';

import logo from "../logo.png";
import Menu from "./Menu";
import BurgerMenuButton from "./BurgetMenuPopup";
import BasketPopup from "./BasketPopup";
import {useAuth0} from "@auth0/auth0-react";
import * as Modules from "./Modules";
import Popup from "reactjs-popup";
import React from "react";

function Header() {
    const {isAuthenticated} = useAuth0()
    return (
        <header className="header">
            <div className="container">
                <div className="header_inner">
                    <Link to='/' className="header-logo"><img src={logo} className="header-logo-img" alt="logo"/></Link>
                    <Menu/>
                    <div className='mobile-hamburger-menu'>
                        <BurgerMenuButton/>
                    </div>
                    {isAuthenticated && process.env.REACT_APP_MYREP === "1" &&
                        <Popup className="my-rep-container" trigger={<span className='nav-linked-in' title={'My Rep'}><i
                            className="fa-2x fa-light fa-address-card"></i></span>} arrow={false}
                               modal
                               nested>
                            {close => (<>
                                <Modules.Fallback fallback={null}>
                                    <Modules.MyRep/>
                                </Modules.Fallback>
                                <div className='close_modal' onClick={() => close()}>&times;</div>
                            </>)}
                        </Popup>
                    }
                    {isAuthenticated &&
                        <>
                        <BasketPopup/>
                            <Link to={'/checkout'}><button
                                className={`checkout-button`}
                            >
                                <p>Review Order</p>
                            </button></Link>
                        </>}
                </div>
            </div>
        </header>
    )
}

export default Header