import React, {Fragment} from "react";

import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneReserve} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/product-item.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields} from "../settings";
import Collapsible from 'react-collapsible';
import BasketListCheckout from "./BasketListCheckout";

export default function ProdItem(props) {

    const Collapse = ({ collapse, children }) => {
        const [isCollapsed, setIsCollapsed] = React.useState(collapse);

        return (
            <>
                <div
                    className={`collapse-content ${isCollapsed ? 'expanded' : 'collapsed'}`}
                    aria-expanded={isCollapsed}
                >
                    {children}
                </div>
                <button
                    className="collapse-button"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? 'Hide' : 'Show'} Rotation Stock
                </button>

            </>
        );
    };




    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases ? props.cases : '')
    const [bottles, setBottles] = useState(props.bottles ? props.bottles : '')
    const [product, setProduct] = useState({})
    const [rotations, setRotations] = useState([])
    const [renderRotations, setRenderRotations] = useState([])
    var StockTBottles = +product.FreeCases * +product.CaseSize + +product.FreeBottles
    var StockTCases = +product.FreeCases
    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id ? id : props.id
    }
    const {getIdTokenClaims} = useAuth0();

     // change data regarding to product depending on page
    async function loadProduct() {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneReserve(prodCode, token.__raw)).mainResult
            setProduct(singleProduct)

            setLoading(false)
            if (!props.id) cart.items.forEach(el => {
                if (el.ProductCode == singleProduct.ProductCode) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el => {
                if (el.ProductCode + el.RotationCode == props.product.ProductCode + props.product.RotationCode) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                    StockTBottles = +el.FreeCases * +el.CaseSize + +el.FreeBottles
                    StockTCases = +el.FreeCases
                }
            })
        }
        setRotations(props.product.Dupes)



    }

    function handleKeyPress() {

    }

    function addToCartHandler() {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if (cases > 0 || bottles > 0) {
            let newCases
            let newBottles
            let newTotal = +cases * currentProduct.CaseSize + +bottles

            newCases = Math.floor(newTotal / currentProduct.CaseSize)
            newBottles = newTotal % currentProduct.CaseSize

            if (newTotal > StockTBottles) {
                setCases(StockTCases)
                newCases = StockTCases
                setBottles(StockTBottles % currentProduct.CaseSize)
                newBottles = (StockTBottles % currentProduct.CaseSize)
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect(() => {
        loadProduct()
    }, [])

    if (isLoading && id) {
        return <Loading/>;
    }

    // Get correct image for product.
    var image = product.Image1_URL ? process.env.REACT_APP_URL_TO_IMAGES + product.Image1_URL
        : (product.TypeCode == 1) ? wineWhite
            : (product.TypeCode == 2) ? wineRed : wineWhiteGrey;

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    return (
        <Fragment>
            {id && (<>
                <h2 className="product-details-title">Product Details</h2>

            </>)}

            <div className={"product-teaser"}>
                <div className={"product-teaser_left_top"}>
                    <h2 className=''>{product.Description}</h2>
                    {productFields.map((el, index) => el.name && !el.combined ? (
                            <div key={index} className="fields">
                                <i className={`fas fa-icon ${el.iconClass}`}></i>
                                <span>{product[el.name]}</span>
                            </div>
                        ) : (
                            <div key={index} className="fields">
                                <i className={`fas fa-icon ${el.iconClass}`}></i>
                                <span>
                                    {el.combined.map((combinedEl, index) => (
                                        <span
                                            key={index}>{product[combinedEl.name]}{index < el.combined.length - 1 && el.separator}</span>
                                    ))}
                                </span>
                            </div>

                        )
                    )}
                </div>

                <div className={"product-teaser_left_bottom"}>




                    <Collapsible trigger={<button className={"collapsible"}>View Rotation Stock</button>}>
                        <table>

                            <thead>
                            <tr>
                                <th>Rotation No</th>
                                <th>Cases</th>
                                <th>Bottles</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.entries(rotations).map(([key,value]) => {
                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value.FreeCases}</td>
                                        <td>{value.FreeBottles}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Collapsible>


                </div>

                <div className={"product-teaser_right_first"}>
                    {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                        <div className="product-list-item_inner input">
                            <h6 className='product-list-item-label cases'>Cases</h6>
                            <label className="product-list-item-input-label"
                                   htmlFor="stock-cases-input">(<b>Stock: </b>{product.FreeCases})</label>
                            <div className='product-list-item__qty-row'>
                                <input type="button" value="-" className="button-minus no_print"
                                       onClick={() => setCases(cases > 0 && cases - 1)} data-field="quantity"
                                />
                                <input type="number" id="stock-cases-input" name="stock-cases-input"
                                       autoComplete="off" max="1" value={cases} placeholder={'0'}
                                       onChange={(e) => setCases(+e.target.value < 0 ? '' : +e.target.value)}
                                />
                                <input type="button" value="+" className="button-plus no_print"
                                       onClick={() => setCases(+cases + 1)} data-field="quantity"
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={"product-teaser_right_second"}>
                    {process.env.REACT_APP_UNIT_FLAG !== "2" &&
                        <div className="product-list-item_inner input">
                            <h6 className='product-list-item-label bottles'>Bottles</h6>
                            <label className="product-list-item-input-label"
                                   htmlFor="stock-bottles-input">(<b>Stock: </b>{product.FreeBottles})</label>
                            <div className='product-list-item__qty-row'>
                                <input type="button" value="-" className="button-minus no_print"
                                       onClick={() => setBottles(bottles > 0 && bottles - 1)}
                                       data-field="quantity"
                                />
                                <input type="number" id="stock-bottles-input" name="stock-bottles-input"
                                       autoComplete="off" value={bottles} placeholder={'0'}
                                       onChange={(e) => setBottles(+e.target.value < 0 ? '' : +e.target.value)}
                                       min="0" max="6"
                                />
                                {bottles < StockTBottles &&
                                    <input type="button" value="+" className="button-plus no_print"
                                           onClick={() => setBottles(+bottles + 1)} data-field="quantity"
                                    />}
                                {bottles >= StockTBottles &&
                                    <input type="button" value="+" className="button-plus no_print"
                                           data-field="quantity"
                                    />}
                            </div>
                        </div>
                    }
                </div>
                <div className={"product-teaser_right_bottom"}>
                    {StockTBottles > 0 &&
                        <button
                            className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}
                            onClick={() => addToCartHandler()}>
                            <p>Add to Order</p>
                            <i className="fas fa-icon fa-shopping-cart"></i>
                        </button>}
                    {StockTBottles < 1 &&
                        <button className={`update-cart-btn disabled update-cart-product-list-table`}
                                disabled>
                            <p>Out of Stock</p>
                            <i className="fas fa-icon fa-shopping-cart"></i>
                        </button>}
                    <div className={`add-to-cart ${cartAdd}`}>{product.Description} added to order</div>
                </div>
            </div>

        </Fragment>
    )
}