
import '../sass/components/product-list.scss';
import '../sass/components/product-list-filter.scss';
import '../sass/components/pagingation.scss'
import {useEffect, useState} from "react";
import ProdItem from "./ProdItem";
import {useHistory, useParams} from "react-router-dom";
import Loading from "./Loading";
import {fetchReserves} from "../apiRequests/api";
import Selects from "./Selects";
import ReactPaginate from 'react-paginate';
import {useAuth0} from "@auth0/auth0-react";
import {useBasePath} from "../hooks/useBasePath";

function ProductList (props) {
    const basePath = useBasePath();
    const [bulkUpdateClass, setBulkUpdateClass] = useState('')

    let {pageParam} = useParams()
    let {pageSizeParam} = useParams()
    const [pageSize, setPageSize] = useState(pageSizeParam)

    const [pageSizeOptions] = useState(
        {
            current: {value: pageSize ? pageSize : 20, label: pageSize ? pageSize : 20},
            options: [
                { value: '10', label: '10' },
                { value: '20', label: '20' },
                { value: '50', label: '50' },
                { value: 'all', label: 'all' },
            ]
        }
    )
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [totalQtyProducts, setTotalQtyProducts] = useState()

    // building parameters to call api
    let fetchParameters = {
        "pagedProducts": {
            PageSize: pageSize,
            StartIndex: 1+(pageParam*pageSizeParam)-pageSizeParam
         },
        "basePath": basePath
    }
    if (props.fetchParams){
        fetchParameters = Object.assign(fetchParameters,props.fetchParams)
    }
    let history = useHistory()
    const handlePageClick = (paginationData) => {
        window.scrollTo({top: 0, behavior: "smooth"});
        pageParam = +paginationData.selected+1
        history.push(`${basePath}/${pageParam}/${pageSizeParam?pageSizeParam:20}`);
    }

    const {getIdTokenClaims} = useAuth0()

    async function loadAllProducts () {
          if (pageSize != pageSizeParam) {
            pageParam = 1
        }
        pageSizeParam = pageSize
        history.push(`${basePath}/${pageParam}/${pageSizeParam}`);
        const token = await getIdTokenClaims()
        if (pageSizeParam === 'all') {
            fetchParameters.pagedProducts = {}
        }
        if (token){
            const data = await fetchReserves(fetchParameters, token.__raw)
            if(data.error){
                setAllProducts(data)
            } else {
                setAllProducts(Object.values(data.mainResult))
                setTotalQtyProducts(data.TotalRowCount)
            }
            setLoading(false)
        } else {
            alert('Failed to connect Auth0!')
            window.location.href = '/'
        }
    }
    function bulkAddHandler () {
        // TODO needs another solve (now is not enough time)
        var addButtons = document.querySelectorAll('.update-cart-product-list-table')
        addButtons.forEach(el=> el.click())
        setBulkUpdateClass('refreshed')
        setTimeout(function(){ setBulkUpdateClass('') }, 500);
    }

    useEffect(  () => {
        setLoading(true)
        loadAllProducts()
        console.log(props.fetchParams)
    },[pageSize,pageParam,props.fetchParams])

    return (
        <div className='product-list-component'>
            <div className={`product-list-filter ${!allProducts.length && 'hide'}`}>
                <div className='product-list-filter_paginate'>
                    {pageSize!=='all' && totalQtyProducts > 10 &&<ReactPaginate
                        forcePage={pageParam-1}
                        pageCount={totalQtyProducts/pageSize}
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />}
                </div>
                {totalQtyProducts > 10 &&
                    <div className='product-list-filter_select'>
                        <label>Per page:</label>
                        <Selects options={pageSizeOptions.options} setPropsValue={setPageSize} value={pageSize}/>
                    </div>
                }
                <div className='bulk-add'>
                    <button onClick={() => bulkAddHandler()} className={`bulk-add-btn ${bulkUpdateClass}`}>Bulk Add<i className="fas fa-icon fa-cart-plus"></i></button>
                </div>
            </div>
            <Loading isLoading={isLoading}/>
            <ul className='product-list'>
                {
                    !(allProducts.error)?(
                            allProducts&&allProducts.map(product => {
                                if(product.FreeCases > 0 || product.FreeBottles > 0) {
                                    return <li className='product-list-item'
                                               key={product.ProductCode + product.RotationCode}><ProdItem
                                        product={product}/></li>;
                                }
                            })
                        ):(
                        <div className="error-message">{allProducts.error}</div>
                    )

                }
            </ul>
            <div className={`product-list-filter product-list-filter_bottom ${!allProducts.length && 'hide'}`}>
                <div className='product-list-filter_paginate'>
                    {pageSize!=='all' && totalQtyProducts > 10 &&<ReactPaginate
                        forcePage={pageParam-1}
                        pageCount={totalQtyProducts/pageSize}
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />}
                </div>
                <div className='product-list-filter_select'>
                    <label>Per page:</label>
                    <Selects options={pageSizeOptions.options} setPropsValue={setPageSize} value={pageSize}/>
                </div>
                <div className='bulk-add'>
                    <button onClick={() => bulkAddHandler()} className={`${bulkUpdateClass}`}>Bulk Add<i className="fas fa-icon fa-cart-plus"></i></button>
                </div>
            </div>

        </div>
    )
}
export default ProductList