import {NavLink} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {LogoutButton} from "./LogoutButton";
import React, {useState} from "react";
import logo from "../logo.png";
import {requiredQtyBottles} from "../settings";


const links = [
    {
        name: 'Products',
        path: '/product-search',
    },
    {
        name: 'Reports',
        path: '/reports',
    },
    {
        name: 'Storage Request',
        path: '/storage-request',
    }
];

const Menu = (props) => {
    const { logout,isAuthenticated } = useAuth0();

    function logoutUser () {
        // dispatch({type: "CHANGE_ACCESS", payload: false})
        logout({ returnTo: window.location.origin })
    }

    return (
            <ul className="nav-menu">
            {isAuthenticated && links.map((link, index) => (
                <li key={index} className='nav-menu_item' onClick={() => props.toggleMenu&&props.toggleMenu()}><NavLink key={index} to={link.path} activeClassName="active">{link.name}</NavLink></li>
            ))}

                {isAuthenticated &&
                    <li className='nav-menu_item nav-menu_item-logout'><a onClick={() => logoutUser()}>Logout</a></li>
                }
            </ul>
    );


}

export default Menu;