import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {fetchOrders, fetchReservesTotals} from "../apiRequests/api";
import Loading from "./Loading";
import "../sass/components/reports.scss"
import {Link, useHistory, useParams} from "react-router-dom";
import {refactorDate} from "../utils/dateRefactor";


function Reports () {
    const [orders, setOrders] = useState("")
    const [isLoading, setLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()
    const [allProducts, setAllProducts] = useState([])

    async function loadAllReserves () {
        const token = await getIdTokenClaims()
        if (token) {
            const data = await fetchReservesTotals(token.__raw)
            if(data.error){
                setAllProducts(data)
            } else {
                setAllProducts(data)
            }
            setLoading(false)
        }
    }




    useEffect(  () => {
        loadAllReserves()
    },[])

  return (
        <div className="page reports-page">
            <h2 className='page-title'>Reports</h2>
            <Loading isLoading={isLoading}/>
            <div className={'content-half totals'}>

                <div className={'box'}>
                    <h3>Total Bottles</h3>
                    <p>{allProducts.bottles}</p>
                </div>

                <div className={'box'}>
                    <h3>Total Cases</h3>
                    <p>{allProducts.cases}</p>
                </div>

                {/*<div className={'box'}>*/}
                {/*    <h3>Last Order Date</h3>*/}
                {/*</div>*/}

            </div>


            <div className={'content-half'}>

                <Link to="/orders-report">
                    <button>Clearance Order Report</button>
                </Link>

                <Link to="/order-items-report">
                    <button>Clearance Order Items Report</button>
                </Link>

                <Link to="/stock-report">
                    <button>Current Stock Report</button>
                </Link>

            </div>
        </div>
    )
}
export default Reports