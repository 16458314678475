import React, {useState} from "react";

import Paper from '@mui/material/Paper';
import {
    SummaryState,
    IntegratedSummary,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableSummaryRow,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

function OrderItems(props) {
    const formatlessSummaryTypes = ['sum'];
    const messages = {
        sum: 'Total',
    };

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 20, 50, 100]);
    const [columns] = useState([
        {name: 'Description', title: 'Item'},
        {name: 'CaseSize', title: 'Case Size'},
        {name: 'Cases', title: 'Case Qty'},
        {name: 'Bottles', title: 'Bottle Qty'},
    ]);
    const [totalSummaryItems] = useState([
        { columnName: 'Cases', type: 'sum' },
        { columnName: 'Bottles', type: 'sum' },
    ]);
    return (
        <>
            <Paper>
                <Grid
                    rows={props.orderArray}
                    columns={columns}
                >
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}

                    />
                    <IntegratedPaging/>
                    <SummaryState
                        totalItems={totalSummaryItems}
                    />
                    <IntegratedSummary />
                    <Table/>
                    <TableHeaderRow/>
                    <TableSummaryRow
                        formatlessSummaryTypes={formatlessSummaryTypes}
                        messages={messages}/>
                    <PagingPanel
                        pageSizes={pageSizes}
                    />
                </Grid>

            </Paper>
        </>
    )
}

export default OrderItems;