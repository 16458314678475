import '../sass/components/privacy-terms-conditions.scss';

function Privacy() {

    return (
        <div className="page privacy-page">
            <h1 className='privacy-page-title'>Privacy policy</h1>
            <p className='privacy-page-text'>Morbi sit amet ex sit amet dolor vulputate malesuada sed a arcu. Donec pellentesque sem at purus aliquam gravida. Fusce rutrum, nunc tincidunt lacinia feugiat, neque nulla hendrerit est, at blandit tortor lectus in nunc. Integer placerat mi quis est aliquam, aliquam suscipit turpis sagittis. Curabitur porttitor sapien id tortor interdum sollicitudin. Praesent ut efficitur libero. Nulla molestie, elit eu vestibulum consequat, enim quam pretium felis, quis aliquam leo nisi sed sapien. Integer eget eros a felis auctor gravida. Sed quis nibh auctor, suscipit urna et, ullamcorper libero. Curabitur vel metus id est tempor vehicula. Maecenas ut tincidunt erat. </p>
        </div>
    )
}
export default Privacy