import React, {useEffect, useState, useRef, useCallback} from "react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Paper from '@mui/material/Paper';
import {
    SummaryState,
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    SearchState,
    FilteringState,
    IntegratedFiltering,
    IntegratedSummary,
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableFilterRow,
    TableGroupRow,
    TableSummaryRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    ExportPanel,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import DateInput from "./DateInput";
import {refactorDateUs, refactorDate} from "../utils/dateRefactor";
import {fetchOrders} from "../apiRequests/api";
import Loading from "./Loading";
import saveAs from 'file-saver';


const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OrdersReport.xlsx');
    });
};

const summaryCalculator = (type, rows, getValue) => {
    if (type === 'median') {
        if (!rows.length) {
            return null;
        }
        const Bin = rows[0].BinNo
        return (Bin)
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const GroupCellContent = ({column, row}) => (
    <span>
      {row.value}
    </span>
);

const DateFormatter = ({ value }) => refactorDate(value);
const ViewFormatter = ({ value }) => <div className='orders__list_col orders__list_col-view button'><Link to={"/order-details/" + value}>View</Link></div>;
const DateTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={DateFormatter}
        {...props}
/>
);

const ViewTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={ViewFormatter}
        {...props}
    />
);

function OrdersReport() {
    const [fields, setFields] = useState({startDate: '01/01/2017', endDate: refactorDateUs(new Date)})
    const [errors, setErrors] = useState({startDate: false, endDate: false})
    const [orders, setOrders] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 20, 50, 100]);

    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()

    async function loadAllOrders() {
        setIsLoading(true)
        let fetchParameters = {
            "orderDateStart": refactorDate(fields.startDate),
            "orderDateEnd": refactorDate(fields.endDate)
        }
        const token = await getIdTokenClaims()
        const data = await fetchOrders(fetchParameters, token.__raw)
        if (data.error) {
            setOrders(data)
        } else {
            setOrders(data.mainResult)
        }
        // alert(JSON.stringify(data.mainResult))
        setIsLoading(false)
    }

    // date validation and loading orders
    function getOrdersHandler() {
        let isValid = true
        var date_regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        const newErorrs = {...errors}
        let startDate = refactorDateUs(fields.startDate)
        let endDate = refactorDateUs(fields.endDate)
        for (const [key, value] of Object.entries(fields)) {
            if (!(date_regex.test(fields[key]))) {
                newErorrs[key] = true
                isValid = false
            } else {
                newErorrs[key] = false
            }
        }
        isValid = startDate > endDate ? (
            newErorrs["startDate"] = true,
                false
        ) : true
        setErrors(newErorrs)
        isValid ? loadAllOrders() : alert('Please select correct date')
    }

    function handleChange(field, value) {
        const newFields = {...fields};
        newFields[field] = refactorDateUs(value)
        setFields(newFields);
    }

    useEffect(() => {
        loadAllOrders()
    }, [])

    const [columns] = useState([
        {name: 'Order_OrderNo', title: 'Clearance No'},
        {name: 'Order_CustomerReference', title: 'Reference'},
        {name: 'Order_OrderDate', title: 'Clearance Date'},
        {name: 'CustomerAccount', title: 'Account'},
        {name: 'Status', title: 'Status'},
        {name: 'View', title: ' '},
    ]);
    const [dateColumns] = useState(['Order_OrderDate']);
    const [viewColumns] = useState(['View']);
;

        const exporterRef = useRef(null);

        const startExport = useCallback(() => {
            exporterRef.current.exportGrid();
        }, [exporterRef]);


        return (
            <div className="page reports-page">
                <h2 className='page-title'>Clearance Report</h2>
                <div className="orders__search">
                    <div className="orders__field datepicker">
                        <p>Order Date Start: </p>
                        <DateInput type={"text"}
                                   value={refactorDateUs(fields.startDate)}
                                   name={"startDate"}
                                   setValue={handleChange}
                                   error={errors.startDate}
                        />
                    </div>
                    <div className="orders__field datepicker">
                        <p>Order Date End:</p>
                        <DateInput type={"text"}
                                   value={refactorDateUs(fields.endDate)}
                                   name={"endDate"}
                                   setValue={handleChange}
                                   error={errors.endDate}
                        />
                    </div>
                    <div className="orders__submit_wrapper">
                        <button className='orders__submit' onClick={() => getOrdersHandler()}>Get Clearances</button>
                    </div>
                </div>
                <Loading isLoading={isLoading}/>
                {isLoading == "" && !orders.error &&
                    <Paper isLoading={isLoading}>
                        <Grid
                            rows={orders}
                            columns={columns}
                        >
                            <DateTypeProvider
                                for={dateColumns}
                            />
                            <ViewTypeProvider
                                for={viewColumns}
                            />
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={setCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={setPageSize}

                            />
                            <SortingState
                                defaultSorting={[{ columnName: 'Order_OrderNo', direction: 'desc' }]}
                            />
                            <IntegratedSorting />
                            <SearchState defaultValue=""/>
                            <FilteringState defaultFilters={[]} />
                            <IntegratedFiltering/>
                            <IntegratedPaging/>


                            <VirtualTable />
                            <Table/>
                            <TableHeaderRow showSortingControls />
                            <TableFilterRow />
                            <PagingPanel
                                pageSizes={pageSizes}
                            />
                            <Toolbar/>
                            <SearchPanel/>
                            <ExportPanel startExport={startExport}/>
                        </Grid>
                        <GridExporter
                            ref={exporterRef}
                            rows={orders}
                            columns={columns}
                            onSave={onSave}

                        />
                    </Paper>
                }
            </div>
        )
    }

export default OrdersReport