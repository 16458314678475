import '../sass/header/basket-button.scss'
import basketicon from "../assets/van.png";
import React from "react";
import {numberWithCommas} from "../utils/extraTools";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

export default function BasketButton (props) {
    const cart = useSelector((state) => state.cart)
    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    let totalBottles = 0
    cart.items&&cart.items.forEach(el => {
        totalNett+=el.CasePrice*el.CasesQty+el.BottlesQty*el.BottleNett
        totalVAT+=el.BottleVAT*(el.CasesQty*el.CaseSize+el.BottlesQty)
        grandTotal = totalNett + totalVAT
        totalBottles += (el.CaseSize*el.CasesQty)+el.BottlesQty
    })

    function toggleBasketPopup() {
        props.toggleBasketPopup()
    }

    return (
        // <button onClick={() => toggleBasketPopup()} className="mobile-basket-btn icon" type="button" >
        <Link to='/basket'>
            <button className="mobile-basket-btn icon" type="button" >
                <img src={basketicon} className='mobile-basket-icon' alt="mobile-basket-icon" />
                <span className='mobile-basket-total-items'>{totalBottles}</span>
            </button>
        </Link>
    )
}