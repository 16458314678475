import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import Paper from '@mui/material/Paper';
import {
    SummaryState,
    GroupingState,
    IntegratedGrouping,
    IntegratedSummary,
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableSummaryRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    ExportPanel,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import DateInput from "./DateInput";
import {refactorDateUs, refactorDate} from "../utils/dateRefactor";
import {fetchOrderLines} from "../apiRequests/api";
import Loading from "./Loading";
import {GridExporter} from "@devexpress/dx-react-grid-export";
import saveAs from 'file-saver';

const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OrderItemsReport.xlsx');
    });
};

const summaryCalculator = (type, rows, getValue) => {
    if (type === 'median') {
        if (!rows.length) {
            return null;
        }
        const SecondaryDescription = rows[0].Description
        return (SecondaryDescription)
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const GroupCellContent = ({column, row}) => (
    <span>
      {row.value}
    </span>
);


function OrderItemsReport() {
    const [fields, setFields] = useState({startDate: '01/01/2017', endDate: refactorDateUs(new Date)})
    const [errors, setErrors] = useState({startDate: false, endDate: false})
    const [orders, setOrders] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 20, 50, 100]);
    const [orderItems, setOrderItems] = useState([]);
    const [orderItem, setOrderItem] = useState([]);
    const [orderItemsTable, setOrderItemsTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()


    async function loadAllOrders() {
        setIsLoading(true)
        let fetchParameters = {
            "orderDateStart": refactorDate(fields.startDate),
            "orderDateEnd": refactorDate(fields.endDate)
        }
        const token = await getIdTokenClaims()
        const data = await fetchOrderLines(fetchParameters, token.__raw)
        if (data.error) {
            setOrders(data)
        } else {
            setOrders(data.mainResult)
        }

        setIsLoading(false)
    }

    // date validation and loading orders
    function getOrdersHandler() {
        let isValid = true
        var date_regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        const newErorrs = {...errors}
        let startDate = refactorDateUs(fields.startDate)
        let endDate = refactorDateUs(fields.endDate)
        for (const [key, value] of Object.entries(fields)) {
            if (!(date_regex.test(fields[key]))) {
                newErorrs[key] = true
                isValid = false
            } else {
                newErorrs[key] = false
            }
        }
        isValid = startDate > endDate ? (
            newErorrs["startDate"] = true,
                false
        ) : true
        // setErrors(newErorrs)
        isValid ? loadAllOrders() : alert('Please select correct date')
    }

    function handleChange(field, value) {
        const newFields = {...fields};
        newFields[field] = refactorDateUs(value)
        setFields(newFields);
    }

    useEffect(() => {
        loadAllOrders()
    }, [])

    const [columns] = useState([
        {name: 'Description', title: 'Item'},
        {name: 'OrderNo', title: 'Clearance No'},
        {name: 'RotationCode', title: 'Rotation No'},
        {name: 'OrderDate', title: 'Order Date'},
        {name: 'CustomerAccount', title: 'Customer'},
        {name: 'DeliveryAccount', title: 'Delivery Name'},
        {name: 'TotalBottles', title: 'Total Bottles'},
    ]);

    const [tableColumnExtensions] = useState([
        {columnName: 'TotalBottles', align: 'right'},
        {columnName: 'Description', align: 'left'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'Description', type: 'count'},
    ]);
    const [grouping] = useState([{columnName: 'CustomerAccount',columnName: 'Description'}]);
    const [groupSummaryItems] = useState([
        {columnName: 'Description', type: 'median', alignByColumn: true},
        {columnName: 'TotalBottles', type: 'sum', alignByColumn: true,},
    ]);

    const exporterRef = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);



    return (
        <div className="page reports-page">
            <h2 className='page-title'>Clearances Items Report</h2>
            <div className="orders__search">
                          <div className="orders__field datepicker">
                    <p>Order Date Start: </p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.startDate)}
                               name={"startDate"}
                               setValue={handleChange}
                               error={errors.startDate}
                    />
                </div>
                <div className="orders__field datepicker">
                    <p>Order Date End:</p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.endDate)}
                               name={"endDate"}
                               setValue={handleChange}
                               error={errors.endDate}
                    />
                </div>
                <div className="orders__submit_wrapper">
                    <button className='orders__submit' onClick={() => getOrdersHandler()}>Get Clearances</button>
                    {/*{JSON.stringify(orders)}*/}
                </div>
            </div>
            <Loading isLoading={isLoading}/>
            {isLoading == "" && !orders.error &&

                <Paper isLoading={isLoading}>
                    <Grid
                        rows={orders}
                        columns={columns}
                    >
                        <GroupingState
                            grouping={grouping}
                        />
                        <SummaryState
                            totalItems={totalSummaryItems}
                            groupItems={groupSummaryItems}
                        />
                        <IntegratedGrouping/>
                        <IntegratedSummary calculator={summaryCalculator}/>
                        <SearchState defaultValue="" />
                        <IntegratedFiltering />
                        <Table
                            columnExtensions={tableColumnExtensions}
                        />
                        <TableHeaderRow/>
                        <TableGroupRow
                            contentComponent={GroupCellContent}

                            showColumnsWhenGrouped
                        />
                        <TableSummaryRow/>
                        <Toolbar />
                        <SearchPanel />
                        <ExportPanel startExport={startExport}/>
                    </Grid>
                    <GridExporter
                        ref={exporterRef}
                        rows={orders}
                        columns={columns}
                        grouping={grouping}
                        totalSummaryItems={totalSummaryItems}
                        groupSummaryItems={groupSummaryItems}
                        onSave={onSave}

                    />
                </Paper>}
        </div>
    )
}

export default OrderItemsReport