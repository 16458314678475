
export const updateCart = (product, cart,action, cases, bottles) => {
    switch (action) {
        case "REMOVE" : {
            let updatedCart = cart.items
            updatedCart = updatedCart.filter(el=> (
                (el.ProductCode + el.RotationCode !== product.ProductCode + product.RotationCode)&&el
            ))
            return updatedCart
        }
        case "ADD" : {
            let productPayload = product
            productPayload.CasesQty = cases
            productPayload.BottlesQty = bottles

            let updatedCart = cart.items
            if (updatedCart.find(el=>el.ProductCode + el.RotationCode == productPayload.ProductCode + productPayload.RotationCode)){
                updatedCart.forEach(elem=>{
                    if(elem.ProductCode + elem.RotationCode == productPayload.ProductCode + productPayload.RotationCode) {
                        elem.CasesQty = productPayload.CasesQty
                        elem.BottlesQty = productPayload.BottlesQty
                    }
                })
            }else {
                updatedCart.push(productPayload)
            }
            return updatedCart
        }
        default:
            return cart.items
    }

}