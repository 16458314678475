import React, {useState} from "react";
import {Formik, Field, Form, ErrorMessage, FieldArray} from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import TextInput from "./formik/TextInput";
import TextArea from "./formik/TextArea";
import Checkbox from "./formik/Checkbox";
import Select from "./formik/Select";
import {fetchSubmitStorage} from "../apiRequests/api";
import "../sass/components/prospects.scss";
import Loading from "./Loading";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import UploadFile from "./UploadFile";


function onChange(value) {
    console.log("Captcha value:", value);
}



function StorageRequest() {
    const recaptchaRef = React.createRef();
    const {getIdTokenClaims} = useAuth0()
    const [prospect, setProspect] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const initialValues = {
        products: [
            {
                binNo: '',
                description: '',
                origin: '',
                country: '',
                caseSize: '',
                bottleQuantity: '',
                bottleSize: '',
                caseQuantity: '',
                vintage: '',
                colour: '',
                price: '',
                organicBiodynamic: '',
            },
        ],
    };


    return (
        <>
            {prospect !== "" && !isError &&
                <>
                    <div className={"confirmation-message"}>Storage Request received</div>
                </>
            }
            {isError &&
                <div className={"error-message"}>Submission Error: <strong>{isError}</strong></div>
            }
            {isLoading &&
                <Loading isLoading={isLoading}/>
            }
            {!isSubmitted && !isLoading &&
                <div className={"prospects"}>
                    <h1>Storage Request</h1>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values) => {
                            setLoading(true);
                            await new Promise((r) => setTimeout(r, 500));
                            const token = await getIdTokenClaims()
                            const response = await fetchSubmitStorage(values, token.__raw);
                            setIsSubmitted(true);
                            setLoading(false);
                            setProspect(response);

                        }}
                    >
                        {({values}) => (
                            <Form>
                                <FieldArray name="products">
                                    {({insert, remove, push}) => (
                                        <div className={'storage_item'}>
                                            {values.products.length > 0 &&
                                                values.products.map((product, index) => (
                                                    <div className="row" key={index}>
                                                        <div className="storage_field">
                                                            <TextInput
                                                                label="Bin No"
                                                                name={`products.${index}.binNo`}
                                                                type="text"
                                                                req="1"
                                                            />
                                                        </div>
                                                        <div className="storage_field description">
                                                            <TextInput
                                                                label="Description"
                                                                name={`products.${index}.description`}
                                                                type="text"
                                                                req="1"
                                                            />
                                                        </div>
                                                        <div className="storage_field">
                                                            <TextInput
                                                                label="Case Size"
                                                                name={`products.${index}.caseSize`}
                                                                type="number"
                                                                req="1"
                                                            />
                                                        </div>
                                                        <div className="storage_field">
                                                        <TextInput
                                                            label="Case Quantity"
                                                            name={`products.${index}.caseQuantity`}
                                                            type="number"
                                                            req="1"
                                                        />
                                                        </div>
                                                        <div className="storage_field">
                                                        <TextInput
                                                            label="Bottle Quantity"
                                                            name={`products.${index}.bottleQuantity`}
                                                            type="number"
                                                            req="1"
                                                        />
                                                        </div>
                                                        <div className="storage_field">
                                                            <Select label="BottleSize" name={`products.${index}.bottleSize`} req="1">
                                                                <option value="">Select Bottle Size</option>
                                                                <option value="20cl">20cl</option>
                                                                <option value="37.5cl">37.5cl</option>
                                                                <option value="75cl">75cl</option>
                                                                <option value="150cl">150cl</option>
                                                                <option value="300cl">300cl</option>
                                                            </Select>
                                                        </div>
                                                        <div className="storage_field">
                                                        <TextInput
                                                            label="Vintage"
                                                            name={`products.${index}.vintage`}
                                                            type="number"
                                                            req="1"
                                                        />
                                                        </div>
                                                        <div className="storage_field">
                                                        <Select label="Colour"  name={`products.${index}.colour`} req="1">
                                                            <option value="">Select Colour</option>
                                                            <option value="Red">Red</option>
                                                            <option value="White">White</option>
                                                            <option value="Rose">Rose</option>
                                                            <option value="Sparkling White">Sparkling White</option>
                                                            <option value="Sparkling Red">Sparkling Red</option>
                                                            <option value="Sparkling Rose">Sparkling Rose</option>
                                                        </Select>
                                                        </div>
                                                        <div className="storage_field">
                                                            <Select label="Transfer Origin" name={`products.${index}.origin`} req="1">
                                                                <option value="">Select Origin</option>
                                                                <option value="UK Warehouse/Production">UK Warehouse/Production</option>
                                                                <option value="Non UK/NI Importation">Non UK/NI Importation</option>
                                                                <option value="N.Ireland Importation">N.Ireland Importation</option>

                                                            </Select>
                                                        </div>
                                                        <div className="storage_field">
                                                            <TextInput
                                                                label="Country"
                                                                name={`products.${index}.country`}
                                                                type="text"
                                                                req="1"
                                                            />
                                                        </div>
                                                        <div className="storage_field">
                                                            <TextInput
                                                                label="Cost Price"
                                                                name={`products.${index}.price`}
                                                                type="number"
                                                                req="1"
                                                            />
                                                        </div>
                                                        {/*<div className="storage_field">*/}
                                                        {/*<Select label="Style" name={`products.${index}.style`} req="1">*/}
                                                        {/*    <option value="">Select Style</option>*/}
                                                        {/*    <option value="Dry / Fresh">Dry / Fresh</option>*/}
                                                        {/*    <option value="Off Dry / Medium">Off Dry / Medium</option>*/}
                                                        {/*    <option value="Light Bodied">Light Bodied</option>*/}
                                                        {/*    <option value="Medium Bodied">Medium Bodied</option>*/}
                                                        {/*    <option value="Full Bodied">Full Bodied</option>*/}
                                                        {/*</Select>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="storage_field">*/}
                                                        {/*<TextInput*/}
                                                        {/*    label="ABV"*/}
                                                        {/*    name={`products.${index}.abv`}*/}
                                                        {/*    type="number"*/}
                                                        {/*    req="1"*/}
                                                        {/*/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="storage_field">*/}
                                                        {/*<Select label="Closure" name={`products.${index}.closure`} req="1">*/}
                                                        {/*    <option value="">Select Closure</option>*/}
                                                        {/*    <option value="Cork">Cork</option>*/}
                                                        {/*    <option value="Plastic Cork">Plastic Cork</option>*/}
                                                        {/*    <option value="Screw Cap">Screw Cap</option>*/}
                                                        {/*</Select>*/}
                                                        {/*</div>*/}
                                                        <div className="storage_field">
                                                        <Select label="Organic/Biodynamic" name={`products.${index}.organicBiodynamic`}
                                                                req="1">
                                                            <option value="">Select Organic/Biodynamic</option>
                                                            <option value="Neither">Neither</option>
                                                            <option value="Organic">Organic</option>
                                                            <option value="Biodynamic">Biodynamic</option>
                                                            <option value="Both">Both</option>
                                                        </Select>
                                                        </div>
                                                        <UploadFile />
                                                        <button
                                                            type="button"
                                                            className="remove"
                                                            onClick={() => remove(index)}
                                                        >
                                                            X
                                                        </button>

                                                    </div>
                                                ))}
                                            <button
                                                type="button"
                                                className="add"
                                                onClick={() => push({ binNo: '',
                                                    description: '',
                                                    origin: '',
                                                    country: '',
                                                    caseSize: '',
                                                    bottleQuantity: '',
                                                    bottleSize: '',
                                                    caseQuantity: '',
                                                    vintage: '',
                                                    colour: '',
                                                    price: '',
                                                    organicBiodynamic: '',})}
                                            >
                                                Add a Product
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                                <button type="submit">Submit Request</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        </>
    );
}

export default StorageRequest;
